import React, {useState} from 'react'
// import GoogleMap from '../../components/GoogleMap/GoogleMap'
import {Container } from 'react-bootstrap';
import GoogleMapWithDirections from '../../components/GoogleDirections/GoogleMapWithDirections';

const Location = () => {
  const [loading, setLoading] = useState()
  const apiKey = 'AIzaSyCNHEa4WLAJvgzoZptLJETQePYSmM8FhTY';
  return (
    <Container className='mt-4 mb-5'>
      <div className="text-center">
        <p className="text-danger">Location : 14800 Buildamerica Dr,
Woodbridge, VA 22191</p>
      </div>
{GoogleMapWithDirections ? <GoogleMapWithDirections apiKey={apiKey} text={"style"}/> :'14800 Build america Dr, Woodbridge, VA 22191' }


    </Container>
  )
}

export default Location