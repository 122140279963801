// src/components/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title ? `${title} | Rita Hair Braiding ` : 'Rita Hair Braiding '}</title>
      <meta name="description" content={description || `Welcome to Rita Braiding Salon.`} />
      <meta name="keywords" content={keywords || 'beauty salon in Woodbridge VA, Hair Braiding Salon Near me,  salon, cornrow, beauty palace, appointments, braiding salon, weaves, stylists'} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Open Graph Tags */}
      <meta property="og:title" content={title || 'Rita Hair Braiding'} />
      <meta property="og:description" content={description || 'Welcome to Rita Braiding Salon.'} />
      <meta property="og:type" content="website" />
      {/* Twitter Card Tags */}
      <meta name="twitter:title" content={title || 'Rita Hair Braiding Salon'} />
      <meta name="twitter:description" content={description || 'Welcome to Rita Beauty Salon.'} />
    </Helmet>
  );
};

export default SEO;
