import React, {useEffect, useState} from 'react'
import Container  from 'react-bootstrap/Container'
import PhotoAlbum from "react-photo-album";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase'

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import SpinnerComponents from '../../components/Spinner/SpinnerComponents';
const Gallery = () => {

  const [collections, setCollections] = useState([]);
  const [index, setIndex] = useState(-1);

  const [isloading, setLoading] = useState(false)

  useEffect(() => {
    const fetchCollections = async () => {
      const collectionsRef = collection(db, 'Gallery');
      const snapshot = await getDocs(collectionsRef);

      console.log(snapshot)
      
      const collectionList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

     
      setCollections(collectionList);

      setLoading(true)
     
      console.log(collectionList)
    };

    fetchCollections();







   




  }, []);
  return (

    <Container>

{!isloading? <SpinnerComponents/>  : <PhotoAlbum layout="rows" photos={collections} targetRowHeight={250} onClick={({ index }) => setIndex(index)}/> }





<Lightbox
        slides={collections}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </Container>

  )
  
     
   
  
}

export default Gallery
